<template>
  <div v-if="loading" class="swiper-container swiper-loading skeleton"></div>

  <div class="swiper-container banner-swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in list" :key="index">
        <router-link :to="item.slideMLink" :title="item.slideAlt" class="swiper-content">
          <img :src="item.slideImg" :alt="item.slideAlt" />
        </router-link>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  nextTick,
  watch,
  onActivated,
  onDeactivated,
} from "vue";
import Swiper from "swiper";
export interface BannerList {
  link: string;
  img: string;
  alt: string;
}

export default defineComponent({
  name: "Swiper",

  props: {
    list: {
      default: [],
      type: Array as PropType<BannerList[]>,
    },
  },
  setup(props) {
    const loading = ref(true);
    const swiper = ref<null | Swiper>(null);

    function initSwiper() {
      swiper.value = new Swiper(".banner-swiper-container", {
        autoplay: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        loop: true, // 循环模式选项
        on: {
          observerUpdate: function () {
            this.slideTo(0);
          },
        },
        // 如果需要分页器

        pagination: {
          el: ".swiper-pagination",
          bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active",
        },
      });
    }

    //  解决页面被重新激活后轮播图失效
    watch(
      () => {
        return props.list;
      },
      () => {
        loading.value = false;
        nextTick(() => {
          if (swiper.value) {
            swiper.value.update();
          }
        });
      }
    );

    onActivated((e: any) => {
      nextTick(() => {
        //  添加定时器，防止轮播不能滚动
        const timer = setTimeout(() => {
          initSwiper();
          clearTimeout(timer);
        }, 1000);
      });
    });
    onDeactivated(() => {
      if (swiper.value) {
        swiper.value.destroy(true,true);
      }
    });

    return {
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
.swiper-loading {
  width: 100%;
  height: 4rem;
  display: block;
  position: relative;
  overflow: hidden;
  position: absolute;
  top: 55px;
}
.swiper-content {
  width: 100%;
  height: 4rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.swiper-content img {
  /*width: 100%;*/
  height: 4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.swiper-container {
  --swiper-theme-color: #fff;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #fff;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 1.067rem;
  /* 设置按钮大小 */
  --swiper-pagination-color: #b9135a;
  /* 两种都可以 */

  height: 150px;

  :deep(.my-bullet) {
    display: inline-block;
    width: 0.347rem;
    height: 0.067rem;
    background-color: #ffffff;
    margin: 0 0.067rem;
    border-radius: 0;
    opacity: 1;
  }
  :deep(.my-bullet-active) {
    background-color: #b9135a;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 74.5%, 0.2) 25%,
    hsla(0, 0%, 50.6%, 0.24) 37%,
    hsla(0, 0%, 74.5%, 0.2) 63%
  );
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}
</style>
